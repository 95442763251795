html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // min-width: 1180px;
  // min-height: 600px;
  width: 100%;
  height: 100%;
  // font-size: 10px;
}

#root {
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
}

.noScroll {
  overflow: hidden;
}

.height-100 {
  height: 100%;
}

.container {
  position: relative;
  overflow: hidden;
  min-width: 960px;
}

.mobilemenulayout {
  display: none;
}

.login {
  top: 30px;
  left: 30px;
  position: absolute;
  a{
    height: 30px;
    width: 30px;
    position: absolute;
    text-decoration: none;
    color: #1277F6;
    -webkit-transition: all 250ms ease-in;
    -moz-transition: all 250ms ease-in;
    -o-transition: all 250ms ease-in;
    transition: all 250ms ease-in;
    &:visited {
      color: #1277F6;
    }
    &:hover {
      color: #E0CAB1;
    }
  }
  svg {
    height: 30px;
    width: 30px;
    position: absolute;
    fill: #1277F6;
    -webkit-transition: all 250ms ease-in;
    -moz-transition: all 250ms ease-in;
    -o-transition: all 250ms ease-in;
    transition: all 250ms ease-in;
    &:hover {
      fill: #ccc;
    }
  }
}

.minimobilefooter {
  display: none;
  background: transparent;
  height: 50px;
  width: 100%;
  text-align: center;
  margin-top: -2px;
  img {
    height: 44px;
    width: auto;
    margin: 0 auto;
    padding: 0 13px;
  }
  #contactmini {
    height: 37px;
    padding-bottom: 3px;
  }
  #signinmini {
    height: 25px;
    padding-bottom: 9px;
  }
}

#logo img {
  float: left;
  position: relative;
  height: 4em;
  width: auto;
}

#hamburger {
  display: none;
}


#navbar {
  position: relative;
  font-family: 'Lato';
  font-weight: lighter;
  color: #1277F6;
  font-size: 1em;
  z-index: 3;
  margin: 0 auto;
  width: 525px;
  top: 10px;
  text-align: center;
  a {
    font-weight: lighter;
    text-decoration: none;
    color: #1277F6;
    width: 100%;
    transition : all 250ms ease-in;
    &:hover {
      color: #AAAAAA;
    }
  }
  ul {
    display: inline-flex;
    list-style-type: none;
    padding: 0;
    margin: 0 auto;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
  li {
    position: relative;
    font-weight: lighter;
  }
}

//
// #navbar a {
//   font-weight: lighter;
//   text-decoration: none;
//   color: #1277F6;
//   width: 100%;
// }

// span#Work {
//   margin: 0 1em 0 1em;
//   right: -12.5em;
//   position: relative;
//   top: 1.35em;
//   font-weight: lighter;
// }

.display-none {
  display: none !important;
}

// span#About {
//   margin: 0 1em 0 1em;
//   right: -33.5em;
//   position: relative;
//   top: -3.1em;
//   font-weight: lighter;
// }

// span#Contact {
//   margin: 0 1em 0 1em;
//   right: -33.5em;
//   position: relative;
//   top: -3.1em;
//   font-weight: lighter;
// }

// span#Solutions {
//   margin: 0 1em 0 1em;
//   right: -12.5em;
//   position: relative;
//   top: 1.35em;
//   font-weight: lighter;
// }


body {
  font-family: 'Lato';
  font-weight: lighter;
  height: 100%;
  width: 100%;
  margin: 0;
}

#logo {
  font-size: 2em;
  font-weight: 500;
  border-style: solid;
  width: 99px;
  padding-left: 0.2em;
  position: relative;
  height: 45px;
  a {
    position: relative;
    right: 3px;
    text-decoration: none;
    line-height: 43px;
  }
}

#Logindiv {
  width: 100%;
  display: none;

  svg {
    width: 30px;
    height: 30px;
  }
}

.darkwrapper {
  height: 100%;
  width: 100%;
  background-color: #000000;
  opacity: 0.6;
  z-index: 10;
}

// @media only screen
// and (min-width: 320px)
// and (max-width: 667px)
// and (-webkit-min-device-pixel-ratio: 2) {
//
//   body {
//     margin: 0;
//   }
//
//   .minimobilefooter {
//     display: inline-block;
//   }
//
//   #hamburger {
//     z-index: 99;
//     display: inline-block !important;
//     position: absolute;
//     top: -10px;
//     right: 0em;
//   }
//
//   $color: #1277F6;		  // icon color
//   $blue: #fff;	  // background color
//   $animation: 0.6s;	// animation speed
//   $scale: 0.6;			  // icon scale 68/68 default
//
//   .hamburglar {
//     transform: scale($scale);
//     margin: 0 auto;
//     position: relative;
//     display: block;
//     width: 68px;
//     height: 68px;
//     -webkit-touch-callout: none;
//     user-select: none;
//   }
//
//   // transition mask
//   .path-burger {
//     position: absolute;
//     top: 0;
//     left: 0;
//     height: 68px;
//     width: 68px;
//     // two masks because... browser support.
//     mask: url(#mask);
//     -webkit-mask-box-image: url(https://raygun.io/upload/mask.svg);
//   }
//
//   .animate-path {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 68px;
//     height: 68px;
//   }
//
//   // what this does is create a small square that I then rotate behind an svg mask, giving the apparence of the line animating
//   .path-rotation {
//     height: 34px;
//     width: 34px;
//     margin: 34px 34px 0 0;
//     transform: rotate(0deg);
//     transform-origin: 100% 0;
//     &:before {
//       content: '';
//       display: block;
//       width: 30px;
//       height: 34px;
//       margin: 0 4px 0 0;
//       background: $color;
//     }
//   }
//
//   // box rotation animation
//   @keyframes rotate-out {
//     0% {
//       transform: rotate(0deg);
//     }
//     40% {
//       transform: rotate(180deg);
//     }
//     100% {
//       transform: rotate(360deg);
//     }
//   }
//
//   @keyframes rotate-in {
//     0% {
//       transform: rotate(360deg);
//     }
//     40% {
//       transform: rotate(180deg);
//     }
//     100% {
//       transform: rotate(0deg);
//     }
//   }
//
//   // offset moves
//   // dasharray is the dash size
//   // need to be able to control dash space size.
//
//   .hamburglar.is-open {
//     .path {
//       animation: dash-in $animation linear normal;
//       animation-fill-mode:forwards;
//       transform: scale(0.8);
//       position: relative;
//       left: 30px;
//     }
//     .animate-path {
//       animation: rotate-in $animation linear normal;
//       animation-fill-mode:forwards;
//     }
//   }
//
//   .hamburglar.is-closed {
//     .path {
//       animation: dash-out $animation linear normal;
//       animation-fill-mode:forwards;
//     }
//     .animate-path {
//       animation: rotate-out $animation linear normal;
//       animation-fill-mode:forwards;
//     }
//   }
//
//   .minimobilefooterinvis{
//     display: none;
//   }
//
//   .minimobilefootervis{
//     display: inline-block;
//   }
//
//   .path {
//     stroke-dasharray: 240;
//     stroke-dashoffset: 240;
//     stroke-linejoin: round;
//   }
//
//   @keyframes dash-in {
//     0% {
//       stroke-dashoffset: 240;
//     }
//     40% {
//       stroke-dashoffset: 240;
//     }
//     100% {
//       stroke-dashoffset: 0;
//     }
//   }
//   @keyframes dash-out {
//     0% {
//       stroke-dashoffset: 0;
//     }
//     40% {
//       stroke-dashoffset: 240;
//     }
//     100% {
//       stroke-dashoffset: 240;
//     }
//   }
//
//
//
//   // All good burgers need filling!
//
//   .burger-icon {
//     position: absolute;
//     padding: 20px 16px;
//     height: 68px;
//     width: 68px;
//   }
//
//   .burger-container {
//     position: relative;
//     height: 28px;
//     width: 36px;
//   }
//
//   .burger-bun-top,
//   .burger-bun-bot,
//   .burger-filling {
//     position: absolute;
//     display: block;
//     height: 4px;
//     width: 36px;
//     border-radius: 2px;
//     background: $color;
//   }
//
//   .burger-bun-top {
//     top: 0;
//     transform-origin: 34px 2px;
//   }
//
//   .burger-bun-bot {
//     bottom: 0;
//     transform-origin: 34px 2px;
//   }
//   //.burger-filling {
//   //  @include transition(all,($animation/2.5),ease-in-//out);
//   //}
//   // relative parent is the button
//   .burger-filling {
//     top: 12px;
//   }
//
//
//
//
//
//   // burger ring container
//   .burger-ring {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 68px;
//     height: 68px;
//   }
//
//   .svg-ring {
//     width: 68px;
//     height: 68px;
//     position: relative;
//     left: 6px;
//     top: 7px;
//   }
//
//
//   // bun animations
//   .hamburglar.is-open {
//     .burger-bun-top {
//       animation: bun-top-out $animation linear normal;
//       animation-fill-mode:forwards;
//     }
//     .burger-bun-bot {
//       animation: bun-bot-out $animation linear normal;
//       animation-fill-mode:forwards;
//     }
//   }
//   .hamburglar.is-closed {
//     .burger-bun-top {
//       animation: bun-top-in $animation linear normal;
//       animation-fill-mode:forwards;
//     }
//     .burger-bun-bot {
//       animation: bun-bot-in $animation linear normal;
//       animation-fill-mode:forwards;
//     }
//   }
//
//   @keyframes bun-top-out {
//     0% {
//       left: 0;
//       top: 0;
//       transform: rotate(0deg);
//     }
//     20% {
//       left: 0;
//       top: 0;
//       transform: rotate(15deg);
//     }
//     80% {
//       left: -5px;
//       top: 0;
//       transform: rotate(-60deg);
//     }
//     100% {
//       left: -5px;
//       top: 1px;
//       transform: rotate(-45deg);
//     }
//   }
//
//   @keyframes bun-bot-out {
//     0% {
//       left: 0;
//       transform: rotate(0deg);
//     }
//     20% {
//       left: 0;
//       transform: rotate(-15deg);
//     }
//     80% {
//       left: -5px;
//       transform: rotate(60deg);
//     }
//     100% {
//       left: -5px;
//       transform: rotate(45deg);
//     }
//   }
//
//
//   @keyframes bun-top-in {
//     0% {
//       left: -5px;
//       bot: 0;
//       transform: rotate(-45deg);
//     }
//     20% {
//       left: -5px;
//       bot: 0;
//       transform: rotate(-60deg);
//     }
//     80% {
//       left: 0;
//       bot: 0;
//       transform: rotate(15deg);
//     }
//     100% {
//       left: 0;
//       bot: 1px;
//       transform: rotate(0deg);
//     }
//   }
//
//   @keyframes bun-bot-in {
//     0% {
//       left: -5px;
//       transform: rotate(45deg);
//     }
//     20% {
//       left: -5px;
//       bot: 0;
//       transform: rotate(60deg);
//     }
//     80% {
//       left: 0;
//       bot: 0;
//       transform: rotate(-15deg);
//     }
//     100% {
//       left: 0;
//       transform: rotate(0deg);
//     }
//   }
//
//
//   // burger filling
//   .hamburglar.is-open {
//     .burger-filling {
//       animation: burger-fill-out $animation linear normal;
//       animation-fill-mode:forwards;
//     }
//   }
//
//   .hamburglar.is-closed {
//     .burger-filling {
//       animation: burger-fill-in $animation linear normal;
//       animation-fill-mode:forwards;
//     }
//   }
//
//   @keyframes burger-fill-in {
//     0% {
//       width: 0;
//       left: 36px;
//     }
//     40% {
//       width: 0;
//       left: 40px;
//     }
//     80% {
//       width: 36px;
//       left: -6px;
//     }
//     100% {
//       width: 36px;
//       left: 0px;
//     }
//   }
//
//   @keyframes burger-fill-out {
//     0% {
//       width: 36px;
//       left: 0px;
//     }
//     20% {
//       width: 42px;
//       left: -6px;
//     }
//
//     40% {
//       width: 0;
//       left: 40px;
//     }
//
//     100% {
//       width: 0;
//       left: 36px;
//     }
//   }
//   #navbar {
//     position: relative;
//     font-family: 'Lato';
//     font-weight: lighter;
//     color: #1277F6;
//     z-index: 3;
//     margin: 0 auto;
//     width: 100%;
//     #logo {
//       font-size: 1.3em;
//       width: 3em;
//       border: 2px #1277F6 solid;
//       top: 0;
//       left: -6px;
//       margin: 0 auto;
//       a {
//         right: 2px;
//       }
//     }
//     #Solutions {
//       display: none;
//     }
//     #About {
//       display: none;
//     }
//     #Work {
//       display: none;
//     }
//     #Contact {
//       display: none;
//     }
//   }
//
//   div#Logindiv {
//     position: absolute;
//     bottom: 2.2em;
//     left: -1em;
//
//     svg {
//       height: 0.8em;
//     }
//   }
//   .mobilemenu {
//     margin: 0px auto;
//     #logo {
//       border-color: #FFFFFF !important;
//       a {
//         color: #FFFFFF;
//       }
//     }
//
//   }
//   .mobilebottom {
//     display: none;
//   }
//
//   .burgermobile {
//     path {
//       stroke: #FFFFFF;
//     }
//   }
//
//   .burgerwhite {
//     span {
//       background: #FFFFFF;
//     }
//   }
//
//   .mobilebodybg {
//     background: #1e5799; /* Old browsers */
//     background: -moz-linear-gradient(45deg, #1e5799 0%, #2989d8 50%, #207cca 63%, #7db9e8 100%); /* FF3.6-15 */
//     background: -webkit-linear-gradient(45deg, #1e5799 0%,#2989d8 50%,#207cca 63%,#7db9e8 100%); /* Chrome10-25,Safari5.1-6 */
//     background: linear-gradient(45deg, #1e5799 0%,#2989d8 50%,#207cca 63%,#7db9e8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
//     filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#7db9e8',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
//     background-attachment: fixed;
//   }
//
//   .mobilemenulayoutvisibile {
//     display: inline-block;
//     height: 100%;
//     width: 100%;
//     margin-top: -130px;
//     img {
//       display: inline-block;
//       height: 110px;
//       position: relative;
//       z-index: 100;
//       width: auto;
//     }
//     #solutionsiconmobile {
//       top: 180px;
//       left: 49px;
//     }
//     #workiconmobile {
//       width: auto;
//       top: 180px;
//       right: 8px;
//     }
//     #mobilemenucross {
//       height: 20em;
//       width: 100%;
//       top: 9px;
//     }
//     #abouticonmobile {
//       bottom: 150px;
//       left: 49px;
//       width: auto;
//     }
//     #contacticonmobile {
//       bottom: 150px;
//       left:28px;
//       width: auto;
//     }
//     span {
//       z-index: 100;
//       color: #FFFFFF;;
//       position: relative;
//       font-size: 26px;
//     }
//     #solmob {
//       width: 20px;
//       margin: 0 auto;
//       top:  192px;
//       left: -66px;
//     }
//     #abmob {
//       bottom: 135px;
//       right: 49px;
//     }
//     #workmob {
//       width: 30px;
//       top: 155px;
//       left: 243px;
//     }
//     #conmob {
//       bottom: 172px;
//       left: 227px;
//     }
//   }
// }
