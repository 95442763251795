.work {

  .flexWrap {
    display: inline-flex;
    justify-content: space-around;
    width: 90%;
    position: relative;
  }

  div#AV {
    font-family: 'Lato';
    font-weight: 300;
    text-align: center;
    color: #E0CAB1;
    position: relative;
    left: 10px;
    margin: 0 auto 0;
    top: 50%;
    transform: translateY(-85%);
    z-index: 9999;
    a {
      transition: color 250ms ease-in;
      top: 0.02em;
    }
    a:hover {
      color: #1277F6;
    }
  }

  #Work a {
    color: #AAAAAA;
  }

  .historystats {
    position: relative;
    width: 163px;
    z-index: 5;
    margin: 0 auto;
    // top: 100px;
    right: 14px;
    font-family: 'Lato';
    font-weight: 300;
  }


  #hist {
    color: #AAAAAA;
    cursor: pointer;
  }

  #stats {
    color: #1277F6;
    position: relative;
    // left: -2em;
    cursor: pointer;
  }

  #statsj {
    margin: 0em;
    right: 3.5em;
    position: relative;
    top: 1.48em;
    color: #AAAAAA;
    display: none;
    cursor: pointer;
  }

  #historystatsj {
    position: relative;
    left: 9.3em;
  }

  #histj {
    right: 10.25em;
    color: #1277F6;
    margin: 0em;
    position: relative;
    top: 1.5em;
    display: none;
    cursor: pointer;
  }

  span#hist {
    // margin: 0 1.5em 0 1.5em;
    // right: -0.6em;
    // top: 1.4em;
    position: relative;
  }

  span#stats {
    // margin: 0 1.5em 0 1.5em;
    // left: 4em;
    // top: 1.4em;
    // position: absolute;
  }

  #A {
    font-family: 'Lato';
    font-size: 11em;
    margin: 0em;
    // padding-right: 0.5em;
    position: relative;
    // top: 1.4em;
    z-index: 2;
    // right: -0.9em;
    cursor: pointer;
    text-decoration: none;
    color: #E0CAB1;
    display: inline-block;
  }

  #V {
    font-family: 'Lato';
    font-size: 11em;
    margin: 0em;
    position: relative;
    // top: 1.4em;
    z-index: 2;
    // left: 0.5em;
    cursor: pointer;
    text-decoration: none;
    color: #E0CAB1;
    display: inline-block;
  }

  span#Pipe {
    position: relative;
    font-size: 11em;
    margin: 0em;
    left: 0.012em;
  }

  div#greybackground {
    z-index: 1;
    position: absolute;
    /*padding: 3em 1em 5.59em 10em;*/
    background-color: #AAAAAA;
    top: 17.56em;
    opacity: .1;
    left: 7em;
    width: 0;
    height: 8.6em;
    margin-left: 11em;
  }

  .work-mobile {
    display: none;
  }

  div#greybackground1 {
    z-index: 1;
    position: absolute;
    /*padding: 3em 1em 5.59em 10em;*/
    background-color: #AAAAAA;
    top: 17.56em;
    opacity: .1;
    left: 18em;
    width: 0;
    height: 8.6em;
    -webkit-transition: -webkit-transform 1s ease-in; /* Changed here */
    -moz-transition: -moz-transform 1s ease-in;
    -o-transition: -o-transform 1s ease-in;
    transition: transform 1s ease-in;
  }

  div#adstrat {
    font-family: 'Lato';
    position: relative;
    top: 3em;
    right: -7.5em;
    display: none;
  }

  div#visualsjquery {
    font-family: 'Lato';
    position: relative;
    top: 3em;
    right: -22.1em;
    display: none;
  }



  div.research  {
    font-family: 'Lato';
    font-weight: 300;
    color: #E0CAB1;
    z-index: 5;
    position: relative;
    text-decoration: none;
    float: right;
    margin-top: -1.5%;
    right: 40px;
    height: 170px;
  }

  div.research a {
    font-family: 'Lato';
    font-weight: 300;
    color: #E0CAB1;
    z-index: 5;
    right: 4em;
    text-decoration: none;
    -webkit-transition : all 130ms ease-in;
    -moz-transition : all 130ms ease-in;
    -o-transition : all 130ms ease-in;
    transition : all 130ms ease-in;
  }

  #Research {
    font-family: 'Lato';
    font-weight: 300;
    color: #000000 !important;
    padding-bottom: 0.2em;
    right: 4em;
  }

  div.research a:hover {
    color: #1277F6;
  }

  #archive a {
    position: relative;
    top: 1em;
    left: 11em;
  }

  .graphline {
    position: absolute;
    bottom: -0.3em;
    left: -0.6em;
  }
  .nodes {
    position: absolute;
    z-index: 5;
    top: -7.8em;
  }

  #text34 {
    display: flex;
    display: none;
    position: relative;
    bottom: 7em;
  }

  .category span:nth-of-type(2) {
    color: #1277F6;
    // display: block;
    // bottom: 220px;
    position: relative;
  }

  .category span:nth-of-type(1) {
    font-weight: bolder;
    font-size: 1.1em;
    color: #000;
  }

  .category {
    text-align: center;
    width: 20vw;
    font-family: 'Lato';
    img {
      height: 50px;
      width: 50px;
      display: none;
      margin: 0 auto 5px;
    }
  }


  .value {
    position: relative;
    display: block;
    color: #000000;
    text-align: center;
    bottom: 340px;
    font-weight: bolder;
    font-size: 1.25em;
    padding-left: 50px;
  }

  .key span {
    padding: 0 0.1em 0 0.1em;
    position: relative;
    top: 19em;
  }

  .value span {
    padding: 0 0.1em 0 0.1em;
    position: relative;
    top: 19em;
    // display: inline-block;
    width: 130px;
  }

  .keyvalue span {
    position: relative;
    // top: 30em;
    display: none;
  }

  .keyvalue {
    position: absolute;
    margin: 50px auto 0;
    width: 100%;
    // display: inline-flex;
    // justify-content: space-around;
  }

  #sprout {
    height: 30px;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 250ms ease-in;
  }


  *:before,
  *:after {
    box-sizing: inherit;
  }



  #chart {
    position: absolute;
    bottom: -5px;
    left: 0%;
  }

  .aspect-ratio {
    height: 0;
    padding-bottom: 50%; /* 495h / 990w : The Intended dimensions */
  }


  @media screen and (max-width: 1039px) {
    .Research {
      display: none;
    }
  }
}