.container {
  min-height: 100%;
  min-width: 960px;
}

.grid {
  width: 960px;
  margin: 0 auto;
  position: relative;
  .helloTitle {
    font-family: 'Lato';
    font-weight: lighter;
    position: relative;
    width: 475px;
    margin: 30px auto 0;
    text-align: center;
    h1 {
      font-size: 54px;
      font-weight: lighter;
      margin: 0;
    }
    h3 {
      font-size: 21px;
      font-weight: lighter;
      margin: 0;
    }
  }
}

#ground-bg {
  position: absolute;
  bottom: 0;
  width: 100%;
}

#foundationBar {
  transform: translate(270%, -340%);
  position: absolute;
  width: 25%;
  left: 50%;
  bottom: 50%;
}

#whoBar {
  transform: translate(-420%, -535%);
  position: absolute;
  left: 50%;
  top: 50%;
}

#whatBar {
  transform: translate(-160%,-265%);
  width: 60%;
  position: absolute;
  left: 50%;
  top: 50%;
}

#whenBar {
  position: absolute;
  width: 60%;
  transform: translate(-180%,-315%);
  left: 50%;
  top: 50%;
}

#whereBar {
  transform: translate(200%,-600%);
  width: 25%;
  position: absolute;
  left: 50%;
  top: 50%;
}

#whyBar {
  position: absolute;
  transform: translate(120%,-470%);
  width: 40%;
  left: 50%;
  top: 50%;
}

// svg {
//   position: absolute;
//   #line {
//     fill-opacity: 0;
//     stroke: #E0CAB1;
//     // stroke-width: 2;
//     stroke-dasharray: 40;
//     stroke-dashoffset: 0;
//     animation: draw 2s linear forwards;
//   }
// }

@keyframes draw {
  from {
    stroke-dashoffset: 40;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.ground {
  position: absolute;
  bottom: 0;
  height: 50%;
  z-index: 1;
  left: 5%;
  width: 90%;
  // .bar {
  //   position: absolute;
  //   height: 20%;
  //   &:nth-of-type(2){
  //     top: -10%;
  //   }
  //   &:nth-of-type(3){

  //   }
  //   &:nth-of-type(4){

  //   }
  //   &:nth-of-type(5){

  //   }
  //   &:nth-of-type(6){

  //   }
  //   &:nth-of-type(7){

  //   }
  // }
}

.bar {
  display: inline-block;
  cursor: pointer;
  width: 20%;
  visibility: hidden;
}
//
//
.server {
  display: inline-block;
  position: absolute;
  cursor: pointer;
  width: 100%;
  left: 50%;
  top: 50%;
  will-change: transform;
  visibility: hidden;
  // bottom: 60%;
  // &:hover {
  //   -webkit-animation: wiggle 0.2s ease infinite alternate;
  //   -moz-animation: wiggle 0.2s ease infinite alternate;
  //   -o-animation: wiggle 0.2s ease infinite alternate;
  //   animation: wiggle 0.2s ease infinite alternate;
  // }
}

#ground {
  display: flex;
  flex-direction: column-reverse;
  -webkit-align-items: center;
  align-items: center;
  bottom: -94%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 28%;
  height: 100%;
}

.secondaryView {
  width: 100vw;
  height: 700vh;
  position: absolute;
  top: 0;
  left: 0;
  .mask {
   position: absolute;
   top: 0;
   left: 0;
   width: 100vw;
   height: 100vh;
   overflow: hidden;
 }
 .wholeSquares {
  width: 100vw;
  height: 100vh;
}
}

#redsquare {
  width: 130%;
  transform: translate(-50%,-115%);
  }

  .animate#redsquare {
    transform: translate(-50%, -250%) skewX(40.8deg) skewY(329.9deg) scale(5);
  }

  #graysquare {
    transform: translate(-65%,-150%);
  }

  .animate#graysquare {
    transform: translate(-50%, -250%) skewX(40.8deg) skewY(329.9deg) scale(5);
  }

  .animate#ltbluesquare {
    transform: translate(-50%, -250%) skewX(40.8deg) skewY(329.9deg) scale(5);
  }

  #ltbluesquare {
    transform: translate(-35%, -150%);
  }

  #streetSign {
    position: absolute;
    z-index: 3;
    width: 9%;
    bottom: 0;
    left: 50%;
    transform: translate(-80%, -15%);
  }

  .animate#yellowsquare {
    transform: translate(-50%, -250%) skewX(40.8deg) skewY(329.9deg) scale(5);
  }

  #yellowsquare {
    transform: translate(-50%,-225%);
    z-index: 4;
  }

  .animate#bluesquare {
    transform: translate(-50%, -250%) skewX(40.8deg) skewY(329.9deg) scale(5);
  }

  #bluesquare {
    transform: translate(-50%,-205%);
    z-index: 3;
  }

  .animate#purplesquare {
    transform: translate(-50%, -250%) skewX(40.8deg) skewY(329.9deg) scale(5);
  }

  #purplesquare {
    left: 50%;
    transform: translate(-50%,-185%);
    z-index: 2;
  }

  #greensquare {
    left: 50%;
    transform: translate(-50%,-245%);
    z-index: 5;
  }

  .animate#greensquare {
    transform: translate(-50%, -250%) skewX(40.8deg) skewY(329.9deg) scale(5);
  }

.rectangles {
  position: relative;
  top: 0;
  left: 0;
  height: 335px;
  width: 125px;
  ul {
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
  }
  li {
    list-style: none;
    height: 100%;
    position: relative;
    margin: 5px 0;
    height: 5px;
    width: 100%;
  }
  .redRect {
    background-color: #E06363;
  }
  .grayRect {
    background-color: #C9E6F4;
  }
}
.circles {
  position: relative;
  top: 20vh;
  left: 43px;

  .smlCircle {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    cursor: pointer;
    position: absolute;
    outline: 1px solid transparent;
    p {
      bottom: 6px;
      font-family: 'Avenir';
      font-weight: lighter;
      color: #FFFFFF;
      position: relative;
      font-size: 13px;
      left: 13px;
    }
  }

  #greencircle {
    position: absolute;
    height: 128px;
    width: 128px;
    background-color: #FFFFFF;
    border: 1px solid #2CDB51;
    border-radius: 100%;
    h1 {
      font: 48px 'Lato';
      font-weight: lighter;
      color: #2CDB51;
      position: relative;
      cursor: pointer;
      width: 100%;
      text-align: center;
      margin: 30px auto;
    }
    img {
      height: 50%;
      position: relative;
      top: 32px;
      display: none;
      margin: 0 auto;
    }
  }

  #redcircle {
    left: -2.6em;
    top: 3em;
    background-color: #E06363;
  }

  #graycircle {
    top: -1em;
    background-color: #C9E6F4;
    left: -1em;
  }

  #ltbluecircle {
    top: -2.6em;
    background-color: #1FE8D9;
    left: 3em;
  }

  #yellowcircle {
    top: -1em;
    background-color: #FBAD18;
    left: 7em;
  }

  #bluecircle {
    top: 3em;
    background-color: #188DB5;
    left: 8.7em;
  }

  #purplecircle {
    top: 6.8em;
    background-color: #B164E8;
    left: 7.2em;
  }

  #greentinycircle {
    top: 26.6em;
    background-color: #2CDB51;
    left: 6.1em;
  }
}

/* 
set width and height on the parent element 
- for this example parent element = .content
*/

.content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: none;
  min-height: 600px;
  .close {
    position: absolute;
    left: 50%;
    top: 15px;
    transform: translate(-50%);
    z-index: 9999;
  }
  .outer {
    position: relative;
    width: 46px;
    cursor: pointer;
  }

  .inner {
    width: inherit;
    text-align: center;
  }

  label { 
    font-family: Avenir;
    font-size: 0.25em; 
    line-height: 3em;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #fff;
    transition: all .35s ease-in;
    opacity: 0;
    cursor: pointer;
    position: relative;
    top: 1px;
  }

  .inner:before, .inner:after {
    position: absolute;
    content: '';
    height: 2px;
    width: inherit;
    background: #fff;
    left: 0;
    transition: all .3s ease-in;
  }

  .inner:before {
    top: 45%; 
    transform: rotate(45deg);  
  }

  .inner:after {  
    bottom: 50%;
    transform: rotate(-45deg);  
  }

  .outer:hover label {
    opacity: 1;
  }

  .outer:hover .inner:before,
  .outer:hover .inner:after {
    transform: rotate(0);
  }

  .outer:hover .inner:before {
    top: 0;
  }

  .outer:hover .inner:after {
    bottom: 0;
  }
}

$numOfSlides: 7;
// indicator options

// $indMt: 10px;
// height adjustments
$slideFrameHeight: $numOfSlides * 100%;
$slideHeight: 100/$numOfSlides * 1%;
// indicator adjustments
// @function indicatorMt() {
//   $mt: ($numOfSlides - 1) * $indMt;
//   $size: $indSize * $numOfSlides;
//   @return ($mt + $size)/2 * -1;
// }


/* layout_slider */

.layout_slider {
  width: 100%;
  height: 100%;
  overflow: hidden;
}


/* slideFrame */

.slideFrame {
  position: relative;
  top: 0;
  width: 100vw;
  height: $slideFrameHeight;
  .slide {
    position: relative;
    width: 100vw;
    height: $slideHeight;
    overflow-x: hidden;
    text-align: center;
    p {
      font: 16px Avenir;
      color: #fff;
      position: absolute;
      bottom: 0;
      width: 100%;
      margin: 0;
      // line-height: 14.28vh;
    }
    h2 {
      font: 192px 'Lato';
      font-weight: 300;
      color: #fff;
      margin: 0;
      line-height: 100vh;
    }
  }
  #slide1 {
    background: #2cdb51;
  }
  #slide2 {
    background: #f7d21c;
    p {
      top: 100vh-85.68vh;
    }
  }
  #slide3 {
    background: #1277f6;
    p {
      top: 100vh-71.4vh;
    }
  }
  #slide4 {
    background: #b164e8;
    p {
      top:100vh-57.12vh;
    }
  }
  #slide5 {
    background: #1fe8d9;
    p {
     top: 100vh-42.84vh;
   }
 }
 #slide6 {
  background: #c9e6f4;
  p {
    top: 100vh-28.56vh;
  }
}
#slide7 {
  background: #e06363;
  p {
    top: 100vh-14.28vh;
  }
}
}


/* indicator */

nav.indicator {
  z-index: 2;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  min-height: 600px;
  .ind {
    position: relative;
    display: block;
    width: 14.28vh;
    height: 14.28vh;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font: 53px Avenir;
    line-height: 14.28vh;
    color: #fff;
    text-transform: uppercase;
    &:nth-child(1){
      background: #2cdb51;
    }
    &:nth-child(2){
      background: #f7d21c;
    }
    &:nth-child(3){
      background: #1277f6;
    }
    &:nth-child(4){
      background: #b164e8;
    }
    &:nth-child(5){
      background: #1fe8d9;
    }
    &:nth-child(6){
      background: #c9e6f4;
    }
    &:nth-child(7){
      background: #e06363;
    }
    sup {
      font-size: 13px;
      top: -3em;
      left: 0.8em;
    }
  }
}

@-webkit-keyframes wiggle
{
  0% {-webkit-transform: scale(1.1);}
  100% {-webkit-transform: scale(1.1);} 
}
@-moz-keyframes wiggle
{
  0% {-moz-transform: scale(1.1);}
  100% {-moz-transform: scale(1.1);}
}
@-o-keyframes wiggle
{
  0% {-o-transform: scale(1.1);}
  100% {-o-transform: scale(1.1);}
}
@keyframes wiggle
{
  0% {transform: scale(1.1);}
  100% {transform: scale(1.1);}
}

@media only screen and (min-height: 800px) and (orientation: portrait){
  #ground {
    bottom: -60%;
    height: 60%;
  }
}