@import './components/calendar';

.contact {
  .bg {
    bottom: 0;
    position: absolute;
  }

  .flatpickr-day.selected {
    border-color: #1277f6 !important;
    background-color: #1277f6 !important;
  }

  .flatpickr-next-month:hover svg {
    fill: #e52525 !important;
  }

  span.flatpickr-day.today:not(.selected):hover {
    border-color: #e52525 !important;
  }

  .terms {
    text-align: center;
    color: #fff;
    position: absolute;
    bottom: 0px;
    z-index: 99;
    transform: translateX(-50%);
    left: 50%;
    a {
      text-decoration: none;
      color: #fff;
      transition: all 150ms;
      font-size: 12px;
      &:hover {
        color: #1277f6;
      }
    }
  }

  .contactForm {
    width: 960px;
    margin: 0 auto;
    position: relative;
    z-index: 10;
    #body {
      font: 15px 'Lato';
    }
  }

  form {
    width: 960px;
    position: relative;
    margin: 0 auto;
    text-align: center;
  }

  #date {
    font: 18px 'Lato';
  }

  $color: #1277f6;

  h1,
  input::-webkit-input-placeholder,
  button {
    font-family: 'Lato';
    transition: all 0.3s ease-in-out;
  }

  textarea::-webkit-input-placeholder {
    color: #1277f6;
  }

  h1 {
    height: 100px;
    width: 100%;
    font-size: 48px;
    color: #000;
    margin-bottom: -60px;
    font-weight: 300;
  }

  h3 {
    font-size: 17px;
    color: #000;
    font-family: 'Lato';
    margin-bottom: -10px;
    font-weight: 300;
  }

  p#budget {
    font: 18px 'Lato';
    line-height: 0;
    text-align: left;
    color: #89a8d3;
    position: relative;
    display: inline-block;
    right: 77px;
    top: -40px;
  }

  .longInput {
    margin: 0;
    width: 960px;
    background-position: -960px 0;
    background-size: 960px 100%;
    border-bottom: solid 1px $color;
  }

  #slider-range-min {
    outline: 0;
    background: #ccc;
    height: 3.6em;
    border: 0;
    border-radius: 0;
    width: 455px;
    display: inline-flex;
    position: relative;
    left: -25px;
    .ui-slider-range {
      background: #db4d57;
      border-radius: 0;
    }
    .ui-slider-handle {
      outline: 0;
      top: 1.2em;
      margin-left: -0.6em;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 0;
      background: #fff;
      cursor: pointer;
      position: absolute;
    }
  }
  #amount {
    display: none;
  }

  #amount2 {
    display: none;
  }

  input {
    margin: 40px 25px;
    width: 200px;
    display: block;
    border: none;
    padding: 10px 0;
    border-bottom: solid 1px $color;
    transition: background 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 96%,
      $color 4%
    );
    background-position: -200px 0;
    background-size: 200px 100%;
    background-repeat: no-repeat;
    color: darken($color, 20%);
    display: inline-block;
    &:focus,
    &:valid {
      box-shadow: none;
      outline: none;
      background-position: 0 0;
      &::-webkit-input-placeholder {
        color: $color;
        font-size: 11px;
        transform: translateY(-20px);
        visibility: visible !important;
      }
    }
  }

  button {
    border: none;
    background: #e52525;
    cursor: pointer;
    border-radius: 3px;
    padding: 6px;
    width: 100px;
    color: #fff;
    display: block;
    margin: 40px auto 0;
    // &:hover {
    //   transform: translateY(-3px);
    // }
  }

  // #navbar {
  //   width: 670px !important;
  //   height: 50px;
  // }
  //
  //
  #Contact a {
    color: #aaaaaa !important;
  }

  .nav-dots {
    list-style: none;
    position: absolute;
    margin: 0;
    padding: 0;
    left: 467px;
    bottom: 180px;
    li {
      height: 5px;
      width: 5px;
      border: 1px solid #aaa;
      border-radius: 50%;
      display: inline-block;
      &.active {
        background: #aaa;
      }
    }
  }

  sup {
    font-size: 80%;
    vertical-align: top;
    position: relative;
    top: 1px;
  }

  .wrapper {
    width: 960px;
    height: 110px;
    position: relative;
    overflow: hidden;
    top: 10px;
  }

  .budget {
    display: block;
    vertical-align: middle;
    text-align: center;
    overflow: visible;
    width: 100%;
    position: absolute;
    top: 50px;
  }

  // Slider

  .slider-wrapper {
    width: 500px;
    display: inline-block;
    position: relative;
    font-family: 'Lato';
    right: 25px;
  }

  .ui-slider {
    background: #efefef;
    border: 1px solid #d2d2d2;
    height: 20px;
    position: relative;
    border-radius: 100px;
  }

  .ui-slider-range {
    background: #76cc66;
    height: 20px;
    position: absolute;
    border-top: 1px solid #78cf67;
    border-bottom: 1px solid #78cf67;
    transform: translateY(-1px);

    &:after {
      content: '';
      width: 100%;
      height: 1px;
      background: #fff;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.3;
    }
  }

  .ui-slider-handle {
    background: #555;
    position: absolute;
    width: 32px;
    height: 32px;
    top: 50%;
    display: block;
    transform: translate(-50%, -50%);
    border-radius: 100px;
    z-index: 10;
    background: linear-gradient(#555, #454545);
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    box-shadow: inset -2px -2px 6px 2px rgba(0, 0, 0, 0.1);
    transition: width 0.1s;

    &:focus {
      outline: none;
    }

    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }

  .ui-state-active {
    width: 22px;
  }

  // Range Indicator

  .range-wrapper {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
  }

  .range {
    background: #fff;
    white-space: nowrap;
    border: 1px solid #d2d2d2;
    border-radius: 2px;
    font-size: 13px;
    letter-spacing: 0.02em;
    color: #555;
    width: 150px;
    z-index: 10;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-left: 67px solid transparent;
      border-right: 67px solid transparent;
      border-top: 6px solid #d2d2d2;
    }
  }

  .range-value {
    width: 74px;
    padding: 8px 0;
    text-align: center;
    display: inline-block;
    transition: background 0.1s;
    color: #373737;
    margin: 0;
    border: none;
    background: none;
  }

  .range-divider {
    width: 1px;
    display: inline-block;

    &:after {
      position: absolute;
      top: 0;
      left: 50%;
      width: 1px;
      height: 100%;
      background: #d2d2d2;
      content: '';
    }
  }

  .range-alert {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: #fff;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border: 1px solid #d2d2d2;
    color: #0e0e0e;
    line-height: 15px;
    transition: right 0.2s;

    &.active {
      right: -15px;
    }
  }

  // Price Markers

  .marker {
    position: absolute;
    top: 100%;
    padding-top: 16px;
    font-size: 13px;
    color: #373737;
    letter-spacing: 0.05em;
    transform: translateX(-50%);

    &:after {
      content: '';
      width: 1px;
      height: 8px;
      background: #d2d2d2;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .marker-0 {
    &:after {
      display: none;
    }
  }

  .marker-25 {
    left: 25%;
  }

  .marker-50 {
    left: 50%;
  }

  .marker-75 {
    left: 75%;
  }

  .marker-100 {
    right: 0;
    transform: translateX(50%);

    &:after {
      display: none;
    }
  }

  #solution {
    -webkit-appearance: none;
    margin: 40px 25px;
    width: 200px;
    display: block;
    border: none;
    padding: 10px 0;
    border-bottom: solid 1px #1277f6;
    background-repeat: no-repeat;
    color: #89a8d3;
    display: inline-block;
    background: none;
    border-radius: 0;
    // font-size: 14px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  // Gears

  .gear-wrapper {
    position: absolute;
    top: -14px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    backface-visibility: hidden;
    width: 150px;
    height: 20px;
    overflow: hidden;
  }

  .gear-large {
    width: 40px;
    height: 40px;
    background: #d2d2d2;
    border-radius: 100px;
    position: absolute;
    top: 18px;
    left: 18px;
    transform-origin: 50% 50%;
    transition: top 0.15s;

    &:after {
      position: absolute;
      content: '';
      width: 28px;
      height: 28px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #e8e8e8;
      border-radius: 100px;
    }

    &:nth-child(2) {
      background: #d2d2d2;
      left: auto;
      right: 18px;

      & .gear-tooth {
        background: #d2d2d2;
      }
    }

    &.active {
      top: 4px;
    }
  }

  .gear-tooth {
    width: 22%;
    height: 114%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: 0 0;
    background: #d2d2d2;
    border-radius: 2px;

    &:nth-child(2) {
      transform: rotate(90deg) translate(-50%, -50%);
    }

    &:nth-child(3) {
      transform: rotate(45deg) translate(-50%, -50%);
    }

    &:nth-child(4) {
      transform: rotate(-45deg) translate(-50%, -50%);
    }
  }

  .calendar {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 39px;
    #calendarOptions {
      #frequency {
        background: none;
        border: none;
        border-left: 1px solid #1277f6;
        border-right: 1px solid #1277f6;
        border-radius: 0;
        -webkit-appearance: none;
        padding: 0 3px;
        font: 14px 'Avenir';
        color: #1277f6;
        display: inline-block;
        position: absolute;
        left: 130px;
        height: 30px;
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
      select {
        width: 100px;
        text-align-last: center;
      }
      img {
        height: 20px;
        position: absolute;
        top: 5px;
        left: 465px;
      }
      #start + input {
        position: absolute;
        font: 14px/30px 'Avenir';
        color: #1277f6;
        background: #fff;
        left: 230px;
        width: 130px;
        height: 30px;
        z-index: 100;
        margin: 0;
        cursor: pointer;
        padding: 0;
        border: none;
        text-align: center;
        &::-webkit-input-placeholder {
          color: #1277f6;
          font-size: 14px !important;
          transform: translateY(0);
          visibility: visible !important;
          opacity: 1 !important;
          font-family: 'Avenir';
        }
      }
      #end + input {
        position: absolute;
        font: 14px/30px 'Avenir';
        color: #1277f6;
        background: #fff;
        left: 595px;
        width: 130px;
        height: 30px;
        z-index: 100;
        margin: 0;
        cursor: pointer;
        padding: 0;
        border: none;
        text-align: center;
        &::-webkit-input-placeholder {
          color: #1277f6;
          font-size: 14px !important;
          transform: translateY(0);
          visibility: visible !important;
          opacity: 1 !important;
          font-family: 'Avenir';
        }
      }
      .calendarPicker {
        margin: 0;
        width: 150px;
        font: 14px 'Avenir';
        color: #1277f6;
        background: none;
        border: none;
        text-align: center;
        position: absolute;
        height: 30px;
        &:first-of-type {
          left: 240px;
          top: -10px;
        }
        &:nth-of-type(2) {
          top: -10px;
          left: 570px;
        }
      }
      #location:focus::-webkit-input-placeholder {
        color: transparent;
      }
      #location:focus:-moz-placeholder {
        color: transparent;
      }
      #location:focus::-moz-placeholder {
        color: transparent;
      }
      #location:focus:-ms-input-placeholder {
        color: transparent;
      }
      #location {
        background: none;
        border: none;
        border-left: 1px solid #1277f6;
        border-right: 1px solid #1277f6;
        border-radius: 0;
        -webkit-appearance: none;
        padding: 0 3px;
        font: 14px 'Avenir';
        color: #1277f6;
        display: inline-block;
        position: absolute;
        left: 725px;
        width: 100px;
        margin: 0;
        height: 30px;
        text-align: center;
        &:focus {
          outline: none;
        }
        &::-webkit-input-placeholder {
          color: #1277f6;
          font-size: 14px !important;
          transform: translateY(0);
          visibility: visible !important;
          opacity: 1 !important;
          font-family: 'Avenir';
        }
      }
    }
  }

  .textarea {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 8px;
    textarea {
      background-color: transparent;
      overflow: auto;
      -webkit-appearance: none;
      border: none;
      padding: 0;
      width: 490px;
      position: absolute;
      left: 125px;
      border-left: 1px solid #1277f6;
      height: 80%;
      border-right: 1px solid #1277f6;
      padding: 0 5px;
      resize: none;
      color: #00318d;
      &:focus {
        outline: none;
      }
    }
    input {
      border: 1px dashed #8fc156;
      text-align: center;
      background: none;
      margin: 0;
      position: absolute;
      left: 635px;
      width: 196px;
      height: 78%;
      padding: 0;
      color: transparent;
      z-index: 99;
      &::-webkit-file-upload-button {
        visibility: hidden;
      }
    }
    .success {
      background: rgba(143, 193, 86, 0.3);
      display: none;
      text-transform: uppercase;
      text-align: center;
      border: 1px dashed #8fc156;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #8fc156;
      font: 13px/87px 'Avenir';
    }
    .default {
      border: 1px dashed #8fc156;
      position: absolute;
      text-transform: uppercase;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #8fc156;
      line-height: 87px;
      font: 13px/87px 'Avenir';
    }
    .file-dummy {
      transition: background 0.3s ease-in-out;
      left: 635px;
      width: 196px;
      height: 78%;
      position: absolute;
    }
    // input[type=file]:valid + .file-dummy {
    //    .success {
    //      display: inline-block;
    //    }
    //    .default {
    //      display: none;
    //    }
    //  }
  }

  #detailsText:focus::-webkit-input-placeholder {
    color: transparent;
  }
  #detailsText:focus:-moz-placeholder {
    color: transparent;
  }
  #detailsText:focus::-moz-placeholder {
    color: transparent;
  }
  #detailsText:focus:-ms-input-placeholder {
    color: transparent;
  }

  .arrow-next {
    display: inline-block;
    position: absolute;
    right: 90px;
    bottom: 100px;
    width: 30px;
    height: 30px;
    animation: right 0.5s alternate infinite ease;
  }

  .arrow-prev {
    display: inline-block;
    position: absolute;
    left: 90px;
    bottom: 100px;
    width: 30px;
    height: 30px;
    animation: left 0.5s alternate infinite ease;
    display: none;
  }

  .dropdown {
    position: relative;
    font-size: 14px;
    color: #333;

    .dropdown-list {
      background: #fff;
      position: absolute;
      top: 30px;
      width: 220px;
      transform-origin: 50% 0;
      transform: scale(1, 0);
      transition: transform 0.15s ease-in-out 0.15s;
      overflow-y: scroll;
      text-align: left;
      z-index: 99;
      height: 120px;
    }

    .dropdown-option {
      display: inline-block;
      padding: 0;
      opacity: 0;
      transition: opacity 0.15s ease-in-out;
      text-align: left;
      width: 200px;
      color: #1277f6;
      cursor: pointer;
      input {
        margin: 0;
        width: auto;
        float: right;
        -webkit-appearance: none;
        height: 12px;
        width: 12px;
        border: 1px solid #1277f6;
        background: none;
        top: 3px;
        &:checked {
          position: relative;
          &:before {
            content: url('/assets/images/check.svg');
            display: block;
            width: 13px;
            height: 13px;
            position: absolute;
            top: -6px;
            left: 1px;
          }
        }
      }
    }

    .dropdown-label {
      display: block;
      // padding: 6px 12px;
      line-height: 1;
      cursor: pointer;
      text-align: left;

      &:before {
        content: url('/assets/images/dropDownArrow.svg');
        float: right;
        right: 3px;
        position: absolute;
        width: 9px;
        transition: all 0.1s;
      }
    }

    &.on {
      .dropdown-list {
        transform: scale(1, 1);
        transition-delay: 0s;

        .dropdown-option {
          opacity: 1;
          transition-delay: 0.2s;
        }
      }

      .dropdown-label:before {
        content: url('/assets/images/dropDownArrow.svg');
        float: right;
        right: 3px;
        position: absolute;
        width: 9px;
        transform: rotate(90deg);
        transition: all 0.1s;
      }
    }

    [type='checkbox'] {
      position: relative;
      margin-right: 4px;
    }
  }

  @keyframes right {
    0% {
      // right: 140px;
      transform: translateX(4px);
    }
    100% {
      transform: translateX(0);
      // right: 136px;
    }
  }

  @keyframes left {
    0% {
      // right: 140px;
      transform: translateX(-4px);
    }
    100% {
      transform: translateX(0px);
      // right: 136px;
    }
  }
  //
  // .bg {
  //   width: 100%;
  //   bottom: 50px;
  //   height: 100%;
  // }
  //
  // #satellite {
  //   top: 3%;
  // }
  //
  // .svg-sun {
  //   right: 20%;
  //     top: 25%;
  // }
  //
  // #cloud-wrap {
  //   bottom: 0;
  // }
  //
  // .waveHorizontals {
  //   bottom: 7.8%;
  // }
  //
  // #waveHorizontal1 {
  //   animation-duration: 48s;
  // }
  //
  // #waveHorizontal2 {
  //   animation-duration: 20s;
  // }
  //
  // #waveHorizontal3 {
  //   animation-duration: 12s;
  // }
  //
  // .blueBG {
  //   height: 9%;
  // overflow: hidden;
  // width: 30%;
  // position: absolute;
  // bottom: 0.5%;
  // left: 0;
  // }
  //
  // @keyframes move{
  //   0% {		transform: translate(-50%,0px) scale(1,1);}
  //   50% {		transform: translate(-25%,0px) scale(1,0.5);}
  //   100% {	transform: translate(0px,0px) scale(1,1);}
  // }
}
