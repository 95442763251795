.about {
  position: relative;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 1;
  background: url('https://res.cloudinary.com/mattkellough/image/upload/v1556735727/media-news/fireplace.jpg') no-repeat;
  background-size: cover;
  transition: 1s opacity;
  opacity: 1;

  .header {
    min-width: 960px;
  }

  .container {
    position: absolute !important;
    height: 92%;
    min-width: 960px !important;
    width: 100%;
  }



  body {
    margin: 0;
    background: #000;
  }
  video {
    position: absolute;
    // top: 50%;
    // left: 50%;
    // min-width: 100%;
    // min-height: 100%;
    width: 100%;
    height: 100%;
    z-index: -100;
    // transform: translateX(-50%) translateY(-50%);
    // background: url('//demosthenes.info/assets/images/polina.jpg') no-repeat;
    // transition: 1s opacity;
    // opacity: 0.65;
    display: none;
  }
  .stopfade {
    opacity: .3;
  }


  #polina {
    display: block;
    width: 7%;
    padding: .3rem;
    border: none;
    margin: 1rem auto;
    font-size: 1.3rem;
    background: rgba(255,255,255,0.23);
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    transition: .3s background;
    position: absolute;
    bottom: 1em;
    right: 3em;
    font-family: 'Lato';
  }

  #polina:hover {
    background: rgba(0,0,0,0.5);
  }


  #fireplace {
    position: absolute;
    top: 0;
    left: 0;
    height: 102vh;
    width: 100.8vw;
  }

  #mediaphilo {
    position: relative;
  }

  .darkwrapper {
    position: fixed;
    z-index: 1 !important;
    top: 0;
  }


  #navbar a{
    color: #FFFFFF !important;
    &:hover {
      color: #E0CAB1 !important;
    }
  }

  #About a {
    color: #E0CAB1 !important;
  }

  #logo {
    color: #FFFFFF !important;
  }

  #Login a {
    color: #FFFFFF !important;
  }

  #Logindiv path {
    fill: #FFFFFF !important;
  }

  #medianewslogo {
    position: relative;
    font-family: 'Lato';
    color: #FFFFFF;
    border: 0.2em solid #FFFFFF;
    z-index: 2;
    left: -3px;
    bottom: -27vh;
    width: 207px;
    margin: 0 auto;
    max-width: 100%;
    z-index: 1;
    width: 239px;
    height: 44px;
  }

  #medianewslogo h1 {
    font-family: 'Lato';
    font-weight: 300;
    font-size: 2em;
    margin-top: 0em;
    margin-bottom: 0em;
    padding-right: 0.5em;
    padding-left: 0.5em;
  }

  #philo {
    font-family: 'Lato';
    z-index: 2;
    font-weight: 300;
    position: relative;
    color: #1277F6;
    font-size: 0.9em;
    bottom: -28vh;
    left: 0vw;
    width: 100px;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }

  #limitedto {
    opacity: 0.9;
    width: 29em;
    font-weight: 300;
    color: #ffffff;
    position: absolute;
    z-index: 2;
    bottom: 125px;
    left: 4vw;
    margin: 0 auto;
    max-width: 100%;
    height: 90px;
  }

  #limitedto h2 {
    font-family: 'Avenir';
    font-weight: 300;
    text-align: center;
    font-size: 2em;
    position: relative;
    top: 0em;
    width: 100%;
    height: 40px;
  }

  #limitedto p {
    text-align: center;
    position: relative;
    bottom: 1.8em;
    left: 0em;
    width: 450px;
    height: 90px;
  }

  #limitedto span {
    font-family: 'Lato';
    margin-top: -1em;
    text-align: center;
    font-size: 0.9em;
    line-height: 1.4em;
    font-style: none;
    left: 2.39em;
  }

  #leadership {
    font-family: 'Lato';
    font-weight: 300;
    z-index: 2;
    color: #FFFFFF;
    width: 15em;
    opacity: 0.9;
    position: relative;
    display: inline-block;
    float: right;
    margin-top: -2%;
  }

  #leadership p {
    font-size: 0.85em;
    text-align: left;
  }

  #leadershiptitle {
    font-size: 0.85em;
    text-align: center;
    font-family: 'Lato';
    font-weight: 300;
  }

  #management {
    padding-top: 1em;
    font-size: 0.85em;
    text-align: center;
    font-family: 'Lato';
    font-weight: 300;
  }

  // @media only screen
  // and (min-width: 320px)
  // and (max-width: 667px)
  // and (-webkit-min-device-pixel-ratio: 2) {
    //
    //   #navbar span {
      //     top: 0.15em;
      //     font-size: 0.8em;
      //     left: 5.8em;
      //     margin: 0em 0.5em 0em 0.5em;
      //   }
      //
      //   a#Login {
        //     font-size: 0.7em;
        //     position: absolute;
        //     top: 69em;
        //   }
        //
        //   #logo {
          //     font-size: 0.8em;
          //     width: 3em;
          //     border: 1px #FFFFFF solid;
          //   }
          //
          //   #fireplace {
            //     width: 80em;
            //     height: 50em;
            //     position: static;
            //     top: 0;
            //     left: 0;
            //   }
            //
            //   #medianewslogo {
              //     position: absolute;
              //     width: 9.7em;
              //     left: 33em;
              //     top: 19em;
              //   }
              //
              //   #medianewslogo h1 {
                //     font-size: 1.5em;
                //   }
                //
                //   #leadership {
                  //     right: -55em;
                  //     width: 9em;
                  //     position: absolute;
                  //   }
                  //
                  //   #philo {
                    //     width: 7em;
                    //     top: 23.7em;
                    //     left: 39.8em;
                    //     position: absolute;
                    //   }
                    //
                    //   #limitedto {
                      //     top: 30em;
                      //     left: 10em;
                      //     width: 30em;
                      //   }
                      //
                      //   #limitedto h2 {
                        //     font-size: 1.5em;
                        //     position: absolute;
                        //     bottom: 4em;
                        //     left: 0.5em;
                        //   }
                        //
                        //   #limitedto p {
                          //     font-size: 1em;
                          //     bottom: -3em;
                          //     left: -6em;
                          //   }
                          //
                          //   #limitedto span {
                            //     font-size: 1em;
                            //   }
                            // }

                          }