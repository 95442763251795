.visuals {
  background: #f5f5f5;

  #pics {
    height: 98.5%;
    margin-top: 1.5%;
    font-size: 0;
    width: 100%;
    overflow: hidden;
    img {
      margin: 0;
      vertical-align: top;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .legend {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 415px;
    p {
      color: #aaaaaa;
      font-size: 12px;
    }
  }

  // #barrymFrame {
  //   // width: 29%;
  //   // position: relative;
  //   // left: 30.2%;
  //   // top: -32%;
  //   // height: 32%;
  //   border: 1px solid #E0CAB1;
  // }

  // .video-container-vimeo {
  //   display: none;
  //   width: 29%;
  //   position: relative;
  //   left: 30.2%;
  //   height: 32%;
  //   border: 1px solid #E0CAB1;
  //   top: -81.4%;
  // }

  .video-container {
    display: none;
    width: 29.99%;
    position: relative;
    left: 30%;
    top: -81.8%;
    height: 33.59%;
    > div {
      background: #000;
    }
    &.show {
      display: block;
    }
  }

  .video-container + .video-container {
    margin-left: 2%;
  }

  .pretty-embed {
    width: 100%;
    height: 100%;
    img {
      height: 100% !important;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  .html5-main-video {
    width: 100%;
    height: 100%;
  }

  #Work a {
    color: #aaaaaa;
  }

  #container {
    min-width: 1040px;
    min-height: 645px;
    height: calc(100vh - 50px - 10px - 1.53%);
    width: 100%;
    position: absolute;
  }

  #createvis {
    font-family: 'Lato';
    color: #aaaaaa;
    font-size: 22px;
    position: relative;
    font-weight: 300;
    line-height: 21px;
    height: 17%;
    width: 15%;
    display: inline-block;
    vertical-align: top;
    min-width: 156px;
    p {
      position: relative;
      left: 1.5%;
    }
  }

  #learnmorecreate {
    position: relative;
    bottom: 18%;
  }

  #learnmorecreate a {
    font-size: 16px;
    position: relative;
    font-family: 'Lato';
    font-weight: 300;
    color: #1277f6;
    text-decoration: none;
    left: 1.5%;
    top: 10px;
    -webkit-transition: all 300ms ease-in;
    -moz-transition: all 300ms ease-in;
    -o-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
    &:hover {
      color: #093976;
    }
  }

  #learnmorecreate img {
    height: 15px;
    position: relative;
    vertical-align: middle;
    width: auto;
  }

  .mask h3 {
    width: 100%;
    height: 100%;
    position: absolute;
    text-align: center;
    transition: all 350ms linear;
    background-color: rgba(170, 170, 170, 0.8);
    opacity: 0;
    color: #e0cab1;
    &:hover {
      opacity: 1;
    }
    &:hover p {
      top: 5%;
      opacity: 1;
    }
  }

  #av-top {
    min-width: 960px;
    position: relative;
    a {
      width: 33px;
      display: block;
      position: relative;
      float: right;
      color: #e0cab1;
      margin-top: -20px;
      font-size: 16px;
      z-index: 999;
      right: 10px;
      text-decoration: none;
      cursor: pointer;
      -webkit-transition: all 250ms ease-in;
      -moz-transition: all 250ms ease-in;
      -o-transition: all 250ms ease-in;
      transition: all 250ms ease-in;
      &:hover {
        color: #1277f6;
      }
    }
  }

  .mask p {
    top: 100%;
    font-family: 'Lato';
    font-weight: 300;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
    font-size: 3vh;
    text-align: center;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    transition: all 350ms ease;
    opacity: 0;
  }

  .pictures {
    position: relative;
    display: inline-block;
    height: 17%;
    width: 15%;
    cursor: pointer;
    visibility: hidden;
    overflow: hidden;
    opacity: 1;
    min-width: 156px;
  }

  .picturesblank {
    position: relative;
    display: inline-block;
    height: 17%;
    width: 15%;
    vertical-align: top;
    min-width: 156px;
  }

  .keys {
    position: relative;
    font-family: 'Avenir-Light';
    color: #e0cab1;
    font-size: 89.2%;
    display: block;
    &:after {
      content: '\00a0 ';
    }
  }

  .values {
    position: relative;
    font-family: 'Avenir-Light';
    color: #aaaaaa;
    font-size: 89.2%;
    height: 100%;
    display: inline-block;
  }

  #iframe-video {
    iframe {
      position: relative;
      height: 100%;
      width: 100%;
      border: none;
    }
  }


  #barrymtext {
    .keys {
      right: 1%;
    }
    .values {
      right: 1%;
    }
  }

  .videoText {
    display: flex;
    left: 60%;
    position: relative;
    width: 15%;
    padding-left: 0.6%;
    max-height: 0;
    transition: all 500ms linear;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    overflow: hidden;
    &.activeText {
      // display: flex;
      // top: -81.1%;
      top: -115.1%;
      max-height: 33.2%;
      .keys {
        margin: 6% 0;
      }
    }
  }

  #iframe-video {
    height: 100%;
  }

  iframe .vp-player-layout {
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }

}
