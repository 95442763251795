.advertising {
  #navbar {
    color: black;
  }

  #navbar a {
    color: black;
  }

  #Login a {
    color: black;
  }

  #Work a {
    color: black;
  }

  #campaigns {
    font-family: 'Lato';
    font-size: 2em;
    text-align: right;
    margin: 2em 1em 0em 0em;
    z-index: 3;
    position: absolute;
    right: 0em;
    cursor: pointer;
    top: 1em;
  }

  #voli {
    margin: 0.4em 0em 0.4em 0em;
  }

  #voli:hover {
    color: #C0C0C0;
  }

  #mtv {
    margin: 0.4em 0em 0.4em 0em;
  }

  #mtv:hover {

  }

  #pepper {
    margin: 0.4em 0em 0.4em 0em;
  }

  #pepper:hover {
    color: #820000;
  }

  #barrym {
    margin: 0.4em 0em 0.4em 0em;
  }

  #barrym:hover {
    color: #E42087;
  }

  #barryimg img {
    top: 0em;
    height: 100%;
    width: 100%;
    z-index: 1;
    position: absolute;
    left: 0em;
    display: none;
  }

  #pinkstripes img {
    left: 4em;
    top: 0em;
    height: 100%;
    width: 40%;
    z-index: 2;
    position: absolute;
    display: none;
  }

  #kfc {
    margin: 0.4em 0em 0.4em 0em;
  }

  #kfc:hover {
    color: #C11728;
  }

  #bud {
    margin: 0.4em 0em 0.4em 0em;
  }

  #bud:hover {
    color: #1266AA;
  }

  #unforgiveable {
    margin: 0.4em 0em 0.4em 0em;
  }

  #asap {
    margin: 0.4em 0em 0.4em 0em;
  }

  #asap:hover {
    color: #000000;
  }

  #pitbullad {
    margin: 0.4em 0em 0.4em 0em;
  }

  #pitbullad:hover {
    color: #E7B740;
  }


  #heat {
    margin: 0.4em 0em 0.4em 0em;
  }

  #heat:hover {
    color: #D00022;
  }

  #Logindiv path {
    fill: #000000;
  }

}