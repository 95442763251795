.container.solutions {
  background: #1277f6;

  #navbar {
    color: #fff !important;
  }

  #navbar a {
    color: #FFFFFF !important;
    &:hover {
      color: #AAAAAA !important;
    }
  }

  #contactheading {
    font-family: 'Lato';
    text-align: center;
    margin: 0 auto 35px;
    color: #fff;
    width: 670px;
    h1 {
      font-weight: 300;
      font-size: 42px;
      z-index: 2;
    }
    h3 {
      margin-top: -1.5em;
      font-weight: 300;
      z-index: 2;
      font-size: 17px;
    }
  }

  #Solutions a {
    color: #AAAAAA !important;
  }

  #Logindiv path {
    fill: #FFFFFF !important;
  }

  .small-boxes {
    width: 13em;
    height: 100%;
    border: 1px solid #fff;
    position: relative;
    z-index: 9;
    margin: 0 5px;
    -webkit-transition : all 100ms ease-in;
    -moz-transition : all 100ms ease-in;
    -o-transition : all 100ms ease-in;
    transition : all 100ms ease-in;
    &:hover {
      background: #fff;
    }
    p {
      font-family: 'Lato';
      font-weight: 300;
      width: 100%;
      text-align: center;
      position: absolute;
      color: #fff;
      bottom: 10px;
      margin: 0;
      transition : all 100ms ease-in;
    }
    img {
      height: 3.5em;
      position: relative;
      top: 1.75em;
    }
    svg {
      position: absolute;
      transform: translate(-50%,-80%);
      top: 50%;
      left: 50%;
      &:hover {
        transition : all 100ms ease-in;
      }
      .cls-1 {
        stroke-width: 1px;
      }
    }
    &#strategy {
      svg {
        height: 28px;
        width: 28px;
        .cls-1 {
          fill: #fff;
        }
      }
    }
    &#analytics {
      svg {
        width: 33px;
      }
    }
    &#mediaPlanning {
      svg {
        width: 50px;
      }
    }
    &#r-d {
      svg {
        width: 28px;
      }
    }
    &#creative {
      svg {
        width: 56px;
        .cls-1 {
          fill: #fff;
        }
      }
    }
    &#videoProduction {
      svg {
        width: 27px;
      }
    }
  }
  .boxes {
    text-align: center;
    cursor: pointer;
    display: flex;
    margin: 10px auto;
    height: 115px;
  }

  #newbusiness {
    width: 28px;
    height: 28px;
  }

  #bothboxes {
    position: relative;
    margin: 0 auto;
    height: 115px;
    width: 980px;
  }

  .brainContainer {
    position: relative;
    height: 340px;
    margin-top: 30px;
    span:not(.isvg) {
      font-size: 14px;
      font-family: 'Lato';
      font-weight: 400;
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100px;
      text-align: center;
      &:nth-of-type(2) {
        transform: translate(-330%, -240%);
      }
      &:nth-of-type(3) {
        transform: translate(-200%, 575%);
      }
      &:nth-of-type(4) {
        transform: translate(-50%, 760%);
      }
      &:nth-of-type(5) {
        transform: translate(165%, 590%);
      }
      &:nth-of-type(6) {
        transform: translate(229%, -210%);
      }
      &:nth-of-type(7) {
        transform: translate(155%, -780%);
      }
    }
    .brain {
      display: block;
      margin: 0;
      width: 470px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
    .brainMediaPlanning {
      display: block;
      margin: 0;
      width: 470px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      display: none;
    }
  }

}