body {
  // height: 100vh !important;
  width: 100%;
  min-width: 960px;
  // min-height: 450px;
  // overflow: hidden;
  background: #fff;
}

.bg {
  background: url('https://res.cloudinary.com/mattkellough/image/upload/v1556735721/media-news/cityBillboard-2.svg');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  bottom: 325px;
  left: 0;
  background-position: bottom right;
  min-width: 960px;
  min-height: 650px;
  height: 100%;
  width: 100%;
}

#navwrapper {
  height: 245px;
  position: relative;
  z-index: 99;
}

#sailBoat {
    position: relative;
    overflow-x: hidden;
        width: 60%;
    height: 10%;
    top: 85%;
  img {
    height: auto;
    width: 10%;
    position: absolute;
    left: 0%;
    z-index: 2;
    bottom: 0;
    animation: sailBoat 300s linear forwards;
  }
}

#landingcopy {
  position: relative;
  width: 100%;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-20%);
}

#landingtext {
  width: 100%;
  position: relative;
  margin: 0 auto;
  p {
    font-family: 'Lato';
    font-weight: normal;
    position: relative;
    top: 10px;
    color: #AAAAAA;
    text-align: center;
    font-size: 0.9em;
    /* right: 7px; */
    z-index: 3;
    margin: 0 auto;
    width: 470px;
  }
}

#landingtexth2 {
  font-family: 'Lato';
  font-weight: lighter;
  font-size: 1.4em;
  position: relative;
  color: #000;
  top: 30px;
  z-index: 3;
  width: 30em;
  text-align: center;
  margin: 0 auto;
}

// #landingtextp {
//   font-family: 'Lato-Light';
//   font-weight: normal;
//   position: relative;
//   top: 10px;
//   color: #AAAAAA;
//   text-align: center;
//   font-size: 0.9em;
//   right: 7px;
//   z-index: 3;
//   margin: 0 auto;
//   width: 470px;
// }

#satellite {
  position: absolute;
  width: 50%;
  height: 10%;
  top: 10%;
  right: 0%;
  overflow: hidden;
}

#satellite img {
  height: 25%;
  width: 3%;
  position: absolute;
  z-index: 2;
  animation: satellite 120s linear infinite alternate;
}

button#Explore {
  position: relative;
  border: 0px;
  font-family: 'Lato';
  font-weight: lighter;
  color: #ffffff;
  background-color: #E52525;
  border-radius: 0.19em;
  font-size: 0.8em;
  padding: 0.5em 1.3em 0.5em 1.3em;
  z-index: 3;
  width: 130px;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  cursor: pointer;
  overflow: hidden;
  &:after {
    background: #fff;
    content: "";
    height: 155px;
    left: -75px;
    opacity: .2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: -10;
  }
  &:hover {
    &:after {
      left: 120%;
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
  &:focus {
    outline: none;
  }
}


.button {
  position: relative;
  width: 100%;
  margin: 40px auto 0 auto;
  text-align: center;
}

button#Explore a {
  text-decoration: none;
  color: #ffffff;
  left: 1px;
  position: relative;
}

#cloud1 img {
  height: 50px;
  position: relative;
  z-index: 1;
}

#cloud2 {
  position: relative;
  width: 200px;
  height: 50px;
  z-index: 1;
  margin-left: 695px;
  bottom: 100px;
}

#cloud2 img {
  height: 50px;
  position: relative;
  left: 85px;
}

#cloud1 {
  width: 200px;
  height: 50px;
  position: relative;
  margin-top: 55px;
  margin-left: 700px;
}

#landingtexth2 h1 {
  font-weight: lighter;
}

#ExploreMobile {
  display: none;
}

.waveHorizontals{
  width: 65.5%;
  height: 5.6%;
  position: absolute;
  overflow: hidden;
  bottom: 12.8%;
  z-index: 1;
  min-width: 840px;
}

.blueBG {
  background: #53c7eb;
  height: 13%;
      overflow: hidden;
      width: 30%;
      position: absolute;
      bottom: 0.5%;
}
.waveHorizontal{
  width: 200%;
  height: 100%;
  display: block;
  position:absolute;
  left:0; bottom:0;
  background-repeat:repeat-x;
  background-position:left bottom;
  background-size:350px 100%;
  transform-origin:0 100% 0;
  animation-name:move;
  animation-timing-function:linear;
  animation-iteration-count:infinite;
}

#waveHorizontal1{
  background-image:url('/assets/images/wave1.svg');
  animation-delay: -2s;
  animation-duration: 12s;
}
#waveHorizontal2{
  background-image:url('/assets/images/wave2.svg');
  animation-delay: -2s;
  animation-duration: 5s;
}
#waveHorizontal3{
  background-image:url('/assets/images/wave3.svg');
  animation-delay: -1s;
  animation-duration: 3s;
}

@keyframes move{
  0% {		transform: translate(-175px,0px) scale(1,1);}
  50% {		transform: translate(-87px,0px) scale(1,0.5);}
  100% {	transform: translate(0px,0px) scale(1,1);}
}

@-webkit-keyframes animateCloud {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: 82%;
  }
}

@-moz-keyframes animateCloud {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: 82%;
  }
}

@keyframes animateCloud {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: 82%;
  }
}

@-webkit-keyframes animateCloud2 {
  0% {
    margin-left: 30%;
  }
  100% {
    margin-left: 100%;
  }
}

@-moz-keyframes animateCloud2 {
  0% {
    margin-left: 30%;
  }
  100% {
    margin-left: 100%;
  }
}

@keyframes animateCloud2 {
  0% {
    margin-left: 30%;
  }
  100% {
    margin-left: 100%;
  }
}

@-webkit-keyframes animateCloud3 {
  0% {
    margin-left: 100%;
  }
  100% {
    margin-left: 0%;
  }
}

@-moz-keyframes animateCloud3 {
  0% {
    margin-left: 100%;
  }
  100% {
    margin-left: 0%;
  }
}

@keyframes animateCloud3 {
  0% {
    margin-left: 100%;
  }
  100% {
    margin-left: 0%;
  }
}

/* ANIMATIONS */

.x1 {
  -webkit-animation: animateCloud3 55s linear infinite alternate;
  -moz-animation: animateCloud3 55s linear infinite alternate;
  animation: animateCloud3 55s linear infinite alternate;
  position: relative;
  height: 13%;
  width: 13%;
  top: 40%;
}

.x2 {
  -webkit-animation: animateCloud2 38s linear infinite alternate;
  -moz-animation: animateCloud2 38s linear infinite alternate;
  animation: animateCloud2 38s linear infinite alternate;
  position: relative;
  top: 60%;
  //
  // -webkit-transform: scale(0.3);
  // -moz-transform: scale(0.3);
  // transform: scale(0.3);
}

.x3 {
  -webkit-animation: animateCloud3 42s linear infinite alternate;
  -moz-animation: animateCloud3 42s linear infinite alternate;
  animation: animateCloud3 42s linear infinite alternate;
  position: relative;
  top: 2%;
}

.x4 {
  -webkit-animation: animateCloud 75s linear infinite alternate;
  -moz-animation: animateCloud 75s linear infinite alternate;
  animation: animateCloud 75s linear infinite alternate;
  position: relative;
  left: 4%;
  top: 37%;
  height: 10%;
  width: 11%;
}

.x5 {
  -webkit-animation: animateCloud2 60s linear infinite alternate;
  -moz-animation: animateCloud2 60s linear infinite alternate;
  animation: animateCloud2 60s linear infinite alternate;
  top: 17%;
  left: 0;
  position: relative;
  height: 9%;
  width: 11%;
}

/* OBJECTS */

.cloud1 {
  position: relative;
  background: url('https://res.cloudinary.com/mattkellough/image/upload/v1556735723/media-news/cloud2.svg') center;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
}

.cloud2 {
  position: relative;
  background: url('https://res.cloudinary.com/mattkellough/image/upload/v1556735723/media-news/cloud1.svg') center;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
}

#cloud-wrap {
  position: absolute;
      z-index: -1;
      height: 100%;
      width: 30%;
      bottom: 0%;
      right: 0%;
      overflow: hidden;
}

.svg-sun{
  width: 22%;
  height: 22%;
  fill: #f1c40f;
  stroke: #f1c40f;
  position: absolute;
  right: 24%;
  top: 28%;

  -webkit-transform-origin: center center;
  -moz-transform-origin: 22% 22%;
  -o-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin:22% 22%;

  -webkit-animation: spin 160s linear infinite;
  -moz-animation: spin 160s linear infinite;
  -o-animation:spin 160s linear infinite;
  -ms-animation: spin 160s linear infinite;
  animation: spin 160s linear infinite, sunArc 160s linear infinite alternate;
}
/* the circle element in the svg itself */
#sun{
  stroke-width:18;
  stroke-dasharray:2;
  stroke-dashoffset:2;
}
/* start spin animation */
@-webkit-keyframes spin{
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes spin{
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-o-keyframes spin{
  100% {
    -o-transform: rotate(360deg);
  }
}
@-ms-keyframes spin{
  100% {
    -ms-transform: rotate(360deg);
  }
}
@keyframes spin{
  100% {
    transform: rotate(360deg);
  }
}

// @keyframes sunArc {
//   0% {
//     right: -20%;
//     top: 20%;
//   }
//   100% {
//     right: 40%;
//     top: -1%;
//   }
// }

@keyframes satellite {
  0% {
    right: 5%;
    top: 5%;
  }
  100% {
    right: 80%;
    top: 50%;
  }
}

@keyframes sailBoat {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(800%);
  }
}

// .bg {
//   width: 100%;
//   bottom: 50px;
//   height: 100%;
// }
//
// #satellite {
//   top: 3%;
// }
//
// .svg-sun {
//   right: 20%;
//     top: 25%;
// }
//
// #cloud-wrap {
//   bottom: 0;
// }
//
// .waveHorizontals {
//   bottom: 7.8%;
// }
//
// #waveHorizontal1 {
//   animation-duration: 48s;
// }
//
// #waveHorizontal2 {
//   animation-duration: 20s;
// }
//
// #waveHorizontal3 {
//   animation-duration: 12s;
// }
//
// .blueBG {
//   height: 9%;
// overflow: hidden;
// width: 30%;
// position: absolute;
// bottom: 0.5%;
// left: 0;
// }
//
// @keyframes move{
//   0% {		transform: translate(-50%,0px) scale(1,1);}
//   50% {		transform: translate(-25%,0px) scale(1,0.5);}
//   100% {	transform: translate(0px,0px) scale(1,1);}
// }
//
// #navbar {
//   width: 670px !important;
//   height: 50px;
// }
